.variants-container {
  margin-top: 64px;
}

.card {
  width: 288px;
  min-height: 269px;
  padding: 32px 18px;

  b {
    width: 212px;
  }

  pre {
    margin-top: 64px;
  }
}

.secondary-title {
  margin-top: 64px;
}

.text {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 0;
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 18px;
  font-weight: 300;
  line-height: 150%;
  width: 927px;
}

@media screen and (max-width: 979px) {
  .small-container, .text {
    width: 90%;
  }
}

@media screen and (max-width: 482px) {
  .subtitle {
    display: none;
  }
}